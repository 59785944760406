var exports = {};
var templateSTR = "(function(f){if(typeof exports===\"object\"&&typeof module!==\"undefined\"){module.exports=f()}else if(typeof define===\"function\"&&define.amd){define([],f)}else{var g;if(typeof window!==\"undefined\"){g=window}else if(typeof global!==\"undefined\"){g=global}else if(typeof self!==\"undefined\"){g=self}else{g=this}defineNamespace()}})(function(){source()});\n";

function template(moduleName, options) {
  if (typeof options === "boolean") {
    options = {
      commonJS: options
    };
  } else if (!options) {
    options = {};
  }

  var str = templateSTR.replace(/defineNamespace\(\)/g, compileNamespace(moduleName)).split("source()");
  str[0] = str[0].trim(); //make sure these are undefined so as to not get confused if modules have inner UMD systems

  str[0] += "var define,module,exports;";
  if (options.commonJS) str[0] += "module={exports:(exports={})};";
  str[0] += "\n";
  if (options.commonJS) str[1] = "return module.exports;" + str[1];
  str[1] = "\n" + str[1];
  return str;
}

exports = exports = function (name, src, options) {
  if (typeof options === "string" && typeof src === "object") {
    var tmp = options;
    options = src;
    src = tmp;
  }

  return exports.prelude(name, options) + src + exports.postlude(name, options);
};

exports.prelude = function (moduleName, options) {
  return template(moduleName, options)[0];
};

exports.postlude = function (moduleName, options) {
  return template(moduleName, options)[1];
};

function camelCase(name) {
  name = name.replace(/\-([a-z])/g, function (_, char) {
    return char.toUpperCase();
  });

  if (!/^[a-zA-Z_$]$/.test(name[0])) {
    name = name.substr(1);
  }

  var result = name.replace(/[^\w$]+/g, "");

  if (!result) {
    throw new Error("Invalid JavaScript identifier resulted from camel-casing");
  }

  return result;
}

function compileNamespace(name) {
  var names = name.split("."); // No namespaces, yield the best case 'global.NAME = VALUE'

  if (names.length === 1) {
    return "g." + camelCase(name) + " = f()"; // Acceptable case, with reasonable compilation
  } else if (names.length === 2) {
    names = names.map(camelCase);
    return "(g." + names[0] + " || (g." + names[0] + " = {}))." + names[1] + " = f()"; // Worst case, too many namespaces to care about
  } else {
    var valueContainer = names.pop();
    return names.map(compileNamespaceStep).concat(["g." + camelCase(valueContainer) + " = f()"]).join(";");
  }
}

function compileNamespaceStep(name) {
  name = camelCase(name);
  return "g=(g." + name + "||(g." + name + " = {}))";
}

export default exports;
export const prelude = exports.prelude,
      postlude = exports.postlude;